/**
 * 导出通用配置
 */
module.exports = {
  // 标题，此项修改后需要重启项目
  title: '松鼠科技铺',
  // 网站标题
  siteTitle: '松鼠科技铺',
  // 网站加载时显示的内容
  siteLoading: '正在为您加载系统资源，请耐心等待',
  // 网站描述
  siteDescription:
    '松鼠科技铺 收录大量各类优秀辅助，找辅助就选松鼠科技铺！',
  // 网站关键字
  siteKeywords:
    '松鼠科技铺',
  // 白名单配置，不用令牌就能访问
  whileList: ['/', '/login', '/about'],
  // 超级管理员
  superAdmin: 'superAdmin',
  // 所有权限
  allPermission: '*:*:*',
  // 备案号
  recordTitle: ''
}
